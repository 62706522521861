<template>
  <div class="row">
    <div class="col">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"></Confirm>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <transition name="slide">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-auto">
                <h5 class="mt-2 mb-0">Planilla de calificaciones</h5>
              </div>
              <div class="col">
                <ul class="nav justify-content-end">
                  <li class="nav-item">
                    <input id="user" type="hidden" value="<?php echo $user->id ?>"/>
                    <b>Filas por p&aacute;gina</b>
                  </li>
                  <li class="nav-item">
                    <select class="form-control" @change="sizeChange($event)"
                            v-model="filasPorPagina">
                      <option v-for="option in sizeoptions" :value="option.value">
                        {{ option.text }}
                      </option>
                    </select>
                  </li>
                  <li class="nav-item">
                    <button class="btn btn-outline-dark" type="button"
                            @click="borrarFiltroBtnOnClick()">
                      <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                      Quitar Filtro
                    </button>
                  </li>
                  <li class="nav-item">
                    <button class="btn btn-outline-primary" data-html="true"
                            data-toggle="tooltip" title="Inscribir un <b>nuevo</b> estudiante" type="button"
                            @click="nuevoBtnOnClick()">
                      <font-awesome-icon icon="fa-plus"/>
                      Nuevo
                    </button>
                  </li>
                  <li class="nav-item">
                    <button class="btn btn-outline-success" data-html="true"
                            data-toggle="tooltip"
                            title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                            type="button"
                            @click="excelBtnOnClick()">
                      <font-awesome-icon icon="fa-file-excel"/>
                      Exportar a Excel
                    </button>
                  </li>
                  <li class="nav-item">
                    <CDropdown
                        inNav
                        class="c-header-nav-items"
                        placement="bottom-end"
                        add-menu-classes="pt-0"
                        :caret="false"
                    >
                      <template #toggler>
                        <CHeaderNavLink title="Ver videos de ayuda">
                          <font-awesome-icon icon="fas fa-question-circle" size="2x"/>
                        </CHeaderNavLink>
                      </template>
                      <CDropdownItem >
                        <a href="#" @click="$refs.modalVideo.mostrarVideo('video-alumno-hoja-ruta.mp4')"
                           class="text-info" title="Ver video de ayuda">
                          <font-awesome-icon icon="fas fa-video" />
                          Ayuda (Hoja ruta)
                        </a>
                      </CDropdownItem>
                      <CDropdownItem >
                        <a href="#" @click="$refs.modalVideo.mostrarVideo('video-alumno-eliminar-nuevo.mp4')"
                           class="text text-danger" title="Ver video de ayuda">
                          <font-awesome-icon icon="fas fa-video" />
                          Ayuda (Eliminar)
                        </a>
                      </CDropdownItem>
                    </CDropdown>
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-3">
                <label class="form-label" for="gestion"><strong>Gestión:</strong></label>
                <select class="form-control pl-1 pr-1 is-valid" id="gestion"
                        v-model="gestion" required="required"
                        @change="actualizaMaterias()"
                >
                  <option value="" selected>:: SELECCIONAR ::</option>
                  <template v-for="(option,key) in gestiones">
                    <optgroup :label="key">
                      <option v-for="suboption in option" :value="suboption.value">
                        {{ suboption.label }}
                      </option>
                    </optgroup>
                  </template>
                </select>
              </div>
              <div class="col-3">
                <label for="alumnoCarrera"><strong>Carrera</strong></label>
                <select id="alumnoCarrera" required="required"
                        v-model="carrera_id" class="form-control is-invalid"
                        @change="actualizarDatos()">
                  <option value="0">:: SELECCIONAR ::</option>
                  <option v-for="option in carreras" :value="option.value">{{ option.label }}
                  </option>
                </select>
              </div>
              <div class="col-3">
                <strong>
                  <CSelect
                      :options="planes"
                      :plain="true"
                      :value.sync="plan_id"
                      label="Plan de Estudios"
                      required="required"
                      @change="actualizarDatos()"
                  >
                  </CSelect>
                </strong>
              </div>
            </div>
          </div>
          <div class="card-body pt-0 pr-1 pb-0 pl-0">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'" :columnsresize="true"/>
          </div>
        </div>
      </transition>
    </div>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalPlanilla"
        size="xl"
    >
      <template #body-wrapper>
        <div class="row pl-2">
          <div class="col-3 text-center" v-if="!hasRole('docente-designa-materia') || hasRole('admin') || hasRole('admin-ub')">
            <form ref="frmdatosplanilla" @submit.prevent="mostrarPlanilla()" novalidate>
              <input type="hidden" v-model="datosplanilla.codigo_alumno">
              <div class="row mt-4">
                <div class="col">
                  <label for="docente" class="form-check-label">Nombre Docente</label>
                  <input v-model="datosplanilla.docente" id="docente" class="form-control"
                         @change="datosplanilla.docente=datosplanilla.docente.toUpperCase()" required/>
                  <div class="invalid-tooltip">
                    El nombre del docente es requerido.
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <label for="fecha_planilla_calificaciones" class="form-check-label">Fecha de impresión de la
                  planilla</label>
                  <input v-model="datosplanilla.fecha_planilla_calificaciones" id="fecha_planilla_calificaciones"
                         class="form-control"
                         type="date" required/>
                  <div class="invalid-tooltip">
                    La fecha de impresión es requerida.
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col text-center">
                  <button type="submit" class="btn btn-primary btn-block">
                    Generar planilla
                  </button><br/>
                  <small><em>Presione este botón para quitar la marca de agua "BORRADOR".</em></small>
                </div>
              </div>
            </form>
          </div>
          <div :class="(!hasRole('docente-designa-materia') || hasRole('admin') || hasRole('admin-ub'))?'col-9':'col-12'">
            <embed ref="reportePdfPlanilla" height="550" src="" type="application/pdf" title="pdf.pdf"
                   width="100%"/>
          </div>
        </div>
      </template>
      <template #header>
        <h4>Planilla de calificaciones
        </h4>
        <CButtonClose @click="modalPlanilla = false"/>
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>
  </div>
</template>

<script>
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import axios from "axios";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import Confirm from "@/views/notifications/Confirm";
import ModalVideo from "@/views/notifications/ModalVideo";
import Toast from "@/views/notifications/Toast";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'Docentes',
  components: {
    Toast,
    ModalVideo,
    Confirm,
    ModalPDF,
    Alerts,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      modalPlanilla: false,
      modalPlanillaRow: null,
      datosplanilla: {
        docente: '',
        fecha_planilla_calificaciones: '',
        borrador: 0
      },
      items: [],
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      gestion: '',
      carrera_id: 0,
      plan_id: 0,
      gestiones: [],
      carreras: [],
      planes: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Semestre', datafield: "semestre", width: '7%', cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Semestre</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por Gestión"});
          }
        },
        {
          text: 'Materia', datafield: "materia", width: '40%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Materia</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por Materia"});
          }
        },
        {
          text: 'Grupo', datafield: "grupo", width: '6%', cellsalign: 'center',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "grupo"});
          }
        },
        {
          text: 'estudiantes_inscritos', datafield: "estudiantes_inscritos", width: '6%', cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Estudiantes<br />Inscritos</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "grupo"});
          }
        },
        {
          text: 'Docente', datafield: "docente", width: '22%', cellsalign: 'center',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "grupo"});
          }
        },
        {
          text: "", width: '19%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: "btn-green",
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return "Planilla de calificaciones";
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            modalPdf.datosplanilla.docente=''
            modalPdf.datosplanilla.fecha=''
            modalPdf.modalPlanillaRow = rowData
            modalPdf.mostrarPlanillaBorrador(true);
            modalPdf.modalPlanilla = true
          }
        },
      ],
      localization: {
        'emptydatastring': "No existen datos, seleccione la gestión, carrera y plan de estudios ",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/' + this.gestion + '/' + this.carrera_id + '/' + this.plan_id + '/listarplanillacalificaciones?token=' + localStorage.getItem("api_token");
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'materia_id', type: "int"},
        {name: 'semestre', type: "int"},
        {name: 'materia', type: "string"},
        {name: 'usuario', type: "string"},
        {name: 'grupo', type: "string"},
        {name: 'docente', type: "string"},
        {name: 'estudiantes_inscritos', type: "int"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'id',
      sortcolumn: 'materia',
      sortdirection: 'asc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf('admin') >= 0) {
          return true
        }
        return roles != null && roles.indexOf(roleUser) >= 0;
      }
    },
    excelBtnOnClick: function () {      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'MateriaNotas' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
    },
    nuevoBtnOnClick: function () {
      this.$router.push({path: '/docentemateria/' + this.$route.params.id + '/nuevo'});
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        this.filasPorPagina = event.target.value;
        if (this.filasPorPagina > 10) {
          this.$refs.gridSystem.height = (50 * this.filasPorPagina) + 118;
          this.$refs.gridSystem.rowsheight = 50;
          this.$refs.gridSystem.autoheight = false;
          this.$refs.gridSystem.autorowheight = false;
        }
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        if (this.filasPorPagina <= 10) {
          this.$refs.gridSystem.autoheight = true;
          this.$refs.gridSystem.autorowheight = true;
        }
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {

      }
    },
    eliminar: function (docenteid, id) {
      modalPdf.$refs.confirm('', 'Eliminar este registro', function (docenteid, id) {
        modalPdf.$refs.gridSystem.updatebounddata();
      })
    },
    actualizarDatos: function (event) {
      if (modalPdf.gestion !== '' && parseInt(modalPdf.carrera_id) >= 0 && parseInt(modalPdf.plan_id) >= 0) {
        sourceLst.url = modalPdf.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/' + modalPdf.gestion + '/' + modalPdf.carrera_id + '/' + modalPdf.plan_id + '/listarplanillacalificaciones?token=' + localStorage.getItem("api_token");
        this.$refs.gridSystem.clearfilters();
      }
    },
    actualizaMaterias: function (event) {
      axios.get(this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/' + modalPdf.gestion + '/parametros?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
            modalPdf.carreras = response.data.carreras;
            if (Object.keys(response.data.planes).length > 1) {
              modalPdf.planes = valorPorDefecto.concat(response.data.planes);
              modalPdf.plan_id = "";
            } else {
              modalPdf.planes = response.data.planes;
              modalPdf.plan_id = response.data.planes[0].value
            }
            modalPdf.actualizarDatos(event);
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    mostrarPlanilla: function () {
      if (!modalPdf.$refs.frmdatosplanilla.checkValidity()) {
        modalPdf.$refs.frmdatosplanilla.classList.add('was-validated');
      } else {
        modalPdf.modalPlanilla = false;
        modalPdf.$refs.confirm.confirm('', '¿Generar la planilla de calificaciones?', function () {
          modalPdf.mostrarPlanillaBorrador(false);
        }, function () {
          modalPdf.modalPlanilla = true;
        })
      }
    },
    mostrarPlanillaBorrador:function (esBorrador) {
      modalPdf.$refs.alert.show('Descargando documento');
      modalPdf.datosplanilla.borrador=esBorrador?1:0;
      axios.post(
          address + '/api/docentereportes/' + localStorage.sedeSeleccionada + '/' + modalPdf.gestion + '/' + modalPdf.carrera_id + '/' + modalPdf.plan_id + '/' + modalPdf.modalPlanillaRow.materia_id + '/' + modalPdf.modalPlanillaRow.grupo + '/planillacalificacionespdf?token=' + localStorage.getItem("api_token"),
          modalPdf.datosplanilla,
          {responseType: 'blob'}
      )
          .then((response) => {
            modalPdf.$refs.reportePdfPlanilla.setAttribute('src', URL.createObjectURL(response.data) + "#view=FitH");
          })
          // .catch(function (error) {
          //   modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          // })
          .finally(function () {
            modalPdf.$refs.alert.hide();
            modalPdf.modalPlanilla = true;
          });
    }
  },
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
    axios.get(this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/gestiones?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          modalPdf.gestiones = response.data.gestiones;
          if (typeof modalPdf.$route.query.gestion === 'undefined' || modalPdf.$route.query.gestion === null) {
            modalPdf.gestion = response.data.mesActual;
          } else {
            modalPdf.gestion = modalPdf.$route.query.gestion
          }
          if (typeof modalPdf.$route.query.carrera_id === 'undefined' || modalPdf.$route.query.carrera_id === null) {
            modalPdf.carrera_id = "0";
          } else {
            modalPdf.carrera_id = modalPdf.$route.query.carrera_id;
          }
          modalPdf.actualizaMaterias();
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  },
}
</script>